import * as Sentry from "@sentry/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createContext, useCallback, useContext, useState } from "react";
import { create } from "zustand";

import Admin from "../admin/Admin";
import type { AdminRolesResponse } from "../admin/Admin";
import { FetcherError } from "../common/lib/httpClient/fetcher";

import Auth from "./Auth";
import type { MeResponse, MfaParams } from "./Auth";
import { LoginSchemaInput } from "./pages/schemas";

import { i18n } from "@/i18n";

type AuthState =
  | "loading"
  | "unauthenticated"
  | "mfa-enroll"
  | "mfa-validate"
  | "authenticated";

type MFATypes = "TOTP" | "HOTP";

type AuthContextType = {
  authState: AuthState;
  user?: MeResponse;
  adminRoles?: AdminRolesResponse;
  currentCustomer?: string;
  isFetchingMe?: boolean;
  mfaType?: MFATypes;
  mfaEnrollSecret?: string;
  mfaEnrollQRcode?: string;
  login: (params: LoginSchemaInput) => Promise<void>;
  mfaValidate: (params: MfaParams) => Promise<void>;
  logout: () => Promise<void>;
  changeCustomer: (customer: string) => void;
};

export const AuthContext = createContext<AuthContextType>({
  authState: "loading",
  login: () => Promise.resolve(),
  mfaValidate: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  changeCustomer: () => {
    return;
  },
});

export const useAuthStore = create<{
  authState: AuthState;
  setAuthState: (authState: AuthState) => void;
}>((set) => ({
  authState: "loading",
  setAuthState: (authState) => set({ authState }),
}));

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { authState, setAuthState } = useAuthStore();
  const queryClient = useQueryClient();

  const {
    data: user,
    refetch: refetchMe,
    isPending: isFetchingMe,
  } = useQuery({
    queryKey: ["me"],
    queryFn: () => {
      return Auth.me()
        .then((resp) => {
          Sentry.setUser({
            username: resp.name,
            email: resp.email,
          });
          setAuthState("authenticated");
          return resp;
        })
        .catch((e) => {
          setAuthState("unauthenticated");
          throw e;
        });
    },
  });
  i18n.changeLanguage(user?.lang);

  const { data: adminRoles, isPending: isFetchingAdminRoles } = useQuery({
    queryKey: ["admin_roles"],
    queryFn: () => Admin.getAdminRoles(),
    enabled: !!user?.is_admin,
  });

  const { mutate: changeCustomer, isPending: isChangingCustomer } = useMutation(
    {
      mutationFn: Auth.changeCustomer,
      onSuccess: (data: MeResponse) => {
        queryClient.setQueryData(["me"], data);
      },
    }
  );

  const [mfaType, setMfaType] = useState<MFATypes>();
  const [mfaEnrollSecret, setMfaEnrollSecret] = useState<string>();
  const [mfaEnrollQRcode, setMfaEnrollQRcode] = useState<string>();
  const [token, setToken] = useState<string>();

  const login = useCallback(async (params: LoginSchemaInput) => {
    const resp = await Auth.login(params);

    setToken(`${resp.token_type} ${resp.auth_token}`);

    if (resp.mfa_enroll) {
      setAuthState("mfa-enroll");
      setMfaEnrollSecret(resp.mfa_secret);
      setMfaEnrollQRcode(resp.mfa_uri);
    } else {
      setAuthState("mfa-validate");
      setMfaType(resp.mfa_type as MFATypes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mfaValidate = useCallback(
    async ({ mfa }: MfaParams) => {
      try {
        await Auth.mfaValidate({ mfa }, token as string);
      } catch (e) {
        if ((e as FetcherError).message === "Unauthorized") {
          setAuthState("unauthenticated");
        }
        throw e;
      }
      setMfaType(undefined);
      setMfaEnrollSecret(undefined);
      setMfaEnrollQRcode(undefined);
      setToken(undefined);
      refetchMe();
    },
    [refetchMe, token, setAuthState]
  );

  const logout = useCallback(async () => {
    await Auth.logout();
    Sentry.setUser(null);
    setAuthState("unauthenticated");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authState,
        mfaType,
        mfaEnrollSecret,
        mfaEnrollQRcode,
        login,
        mfaValidate,
        logout,
        user,
        adminRoles,
        currentCustomer: user?.current_customer,
        isFetchingMe:
          isFetchingMe ||
          isChangingCustomer ||
          (!!user?.is_admin && isFetchingAdminRoles),
        changeCustomer,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
