import { createContext } from "react";

type MenuRootContextType = {
  open: boolean;
  toggleOpen: () => void;
  close: () => void;
};

export const MenuRootContext = createContext<MenuRootContextType>({
  open: false,
  toggleOpen: () => {
    return;
  },
  close: () => {
    return;
  },
});
