import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useAuth } from "@/modules/auth/AuthContext";
import { AppLayoutSkeleton } from "@/modules/common/components/layout/AppLayout";

const ProtectedRoutes: React.FC = () => {
  const { authState } = useAuth();
  const currentLocation = useLocation();

  if (authState === "loading") {
    return <AppLayoutSkeleton />;
  }

  if (authState !== "authenticated") {
    const fromPath = encodeURIComponent(
      currentLocation.pathname + currentLocation.search
    );

    return <Navigate to={`/login?r=${fromPath}`} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
