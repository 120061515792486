import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";

import AdminRoutes from "@/modules/admin/routes";
import { useAuth } from "@/modules/auth/AuthContext";
import QueryErrorBoundary from "@/modules/common/components/error/QueryErrorBoundary";
import AppLayout, {
  AppLayoutSkeleton,
} from "@/modules/common/components/layout/AppLayout";
import Page404 from "@/modules/common/components/layout/pages/Page404";
import Home from "@/modules/home/pages/Home";

const Login = lazy(() => import("@/modules/auth/pages/Login"));
const Products = lazy(() => import("@/modules/products/Products"));
const Profile = lazy(() => import("@/modules/auth/pages/Profile/Profile"));
const SolutionsRoutes = lazy(() => import("@/modules/solutions/routes"));

const AppRouter = () => {
  const { user } = useAuth();

  return (
    <BrowserRouter>
      <Suspense fallback={<AppLayoutSkeleton />}>
        <Routes>
          <Route
            element={
              <QueryErrorBoundary>
                <PublicRoutes />
              </QueryErrorBoundary>
            }
          >
            <Route
              path="/login"
              element={
                <QueryErrorBoundary>
                  <Login />
                </QueryErrorBoundary>
              }
            />
          </Route>
          <Route
            element={
              <QueryErrorBoundary>
                <ProtectedRoutes />
              </QueryErrorBoundary>
            }
          >
            <Route
              path="/"
              element={
                <QueryErrorBoundary>
                  <AppLayout />
                </QueryErrorBoundary>
              }
            >
              <Route index element={<Home />} />
              {user?.is_admin && (
                <Route path="/admin/*" element={<AdminRoutes />} />
              )}
              <Route path="/solutions/*" element={<SolutionsRoutes />} />
              <Route path="/products" element={<Products />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="*" element={<Page404 />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRouter;
