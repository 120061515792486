import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Typography,
  colors,
} from "@mui/material";
import { Trans } from "react-i18next";

const BADGE_COLORS = {
  ESG: colors.purple[800],
  Midia: colors.orange[800],
  "Inteligencia competitiva": colors.lightBlue[700],
} as Record<string, string>;

type SolutionCardProps = {
  badge: string;
  imgPath: string;
  title: string;
  description: string;
  onClickLearnMore?: () => void;
};

const SolutionCard = ({
  badge,
  imgPath,
  title,
  description,
  onClickLearnMore,
}: SolutionCardProps) => {
  return (
    <Card
      elevation={6}
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
        borderRadius: "24px",
      }}
    >
      <CardMedia
        component="img"
        height="194"
        image={imgPath}
        sx={{
          objectPosition: "50% 35%",
        }}
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Chip
          label={badge}
          sx={{
            marginBottom: 2,
            color: "#fff",
            backgroundColor: BADGE_COLORS[badge],
          }}
          size="small"
        />
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {title}
        </Typography>
        <Typography sx={{ fontSize: "14px" }}>{description}</Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <Button size="small" onClick={onClickLearnMore}>
          <Trans>Learn more</Trans>
        </Button>
      </CardActions>
    </Card>
  );
};

export default SolutionCard;
