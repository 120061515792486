import HttpClient from "@/modules/common/lib/httpClient";

type QuizzCustom = {
  single: boolean;
};

type QuizzTranslation = {
  title: string;
  options?: string[];
};

type QuizzResponse = {
  id: string;
  type: string;
  custom: QuizzCustom;
  translations: Record<string, QuizzTranslation>;
};

const getQuizz = async (): Promise<QuizzResponse> => {
  return HttpClient.get("/quizzes");
};

type AnswerParams = {
  id: string;
  data: { answer: string | string[] };
};
const answer = ({ id, ...data }: AnswerParams) => {
  return HttpClient.post(`/quizzes/${id}/answer`, data.data);
};

export default {
  getQuizz,
  answer,
};
