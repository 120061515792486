import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { useTheme } from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";

import BackButtonLink from "@/modules/common/components/BackButtonLink";

type Props = {
  icon?: React.ElementType;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  actions?: React.ReactNode;
};

const ErrorPage: React.FC<Props> = ({
  icon: Icon = ReportGmailerrorredIcon,
  title = "Unexpected Error",
  subtitle = "Sorry, something went wrong.",
  actions,
}) => {
  const theme = useTheme();

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        maxHeight: "100vh",
        textAlign: "center",
        backgroundColor: theme.palette.background.default,
        px: {
          xs: 2,
          sm: 4,
        },
      }}
    >
      <Icon
        sx={{
          fontSize: {
            xs: "4rem",
            sm: "8rem",
          },
          color: theme.palette.grey[600],
        }}
      />
      <Typography
        variant="h2"
        sx={{
          my: 4,
          fontSize: {
            xs: "2rem",
            sm: "4rem",
          },
        }}
      >
        {title}
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 4 }}>
        {subtitle}
      </Typography>
      {actions ?? <BackButtonLink variant="contained" />}
    </Container>
  );
};

export default ErrorPage;
