import { Navigate, Outlet, useSearchParams } from "react-router-dom";

import { useAuth } from "@/modules/auth/AuthContext";
import { AppLayoutSkeleton } from "@/modules/common/components/layout/AppLayout";

const PublicRoutes: React.FC = () => {
  const { authState } = useAuth();
  const [searchParams] = useSearchParams();

  if (authState === "loading") {
    return <AppLayoutSkeleton />;
  }

  if (authState === "authenticated") {
    const fromPath = searchParams.get("r") || "/";

    return <Navigate to={fromPath} replace />;
  }

  return <Outlet />;
};

export default PublicRoutes;
