import { NewPasswordSchemaType } from "../common/schemas";

import { LoginSchemaInput } from "./pages/schemas";

import HttpClient from "@/modules/common/lib/httpClient";

type LoginResponse = {
  auth_token: string;
  token_type: string;
  mfa_type: string;
  mfa_enroll: boolean;
  mfa_secret?: string;
  mfa_uri?: string;
};

export type MfaParams = {
  mfa: string;
};

type AuthResponse = {
  access_token: string;
  token_type: string;
};

export type MenuSolutionType = "dashboards" | "compass" | "file" | "requests";

type MenuSolution = {
  id: string;
  name: string;
  types: MenuSolutionType[];
};

type Customer = {
  id: string;
  name: string;
};

export type MeResponse = {
  name: string;
  email: string;
  company: string;
  job_title?: string;
  image?: string;
  lang: string;
  locale: string;
  timezone: string;
  solutions: MenuSolution[];
  customers?: Customer[];
  current_customer?: string;
  is_admin: boolean;
};

const login = async (params: LoginSchemaInput): Promise<LoginResponse> =>
  HttpClient.post(`/auth/login`, params);

const mfaValidate = async (
  params: MfaParams,
  token: string
): Promise<AuthResponse> =>
  HttpClient.post(`/auth/mfa`, params, {
    headers: {
      Authorization: token,
    },
  });

const logout = async (): Promise<void> => HttpClient.get(`/auth/logout`);

const me = async (): Promise<MeResponse> => {
  return HttpClient.get(`/auth/me`);
};

const changeCustomer = async (customerId: string): Promise<MeResponse> =>
  HttpClient.patch(`/auth/me`, { current_customer: customerId });

const changePassword = async (data: NewPasswordSchemaType): Promise<void> =>
  HttpClient.put(`/auth/password`, data);

export default {
  me,
  login,
  mfaValidate,
  logout,
  changeCustomer,
  changePassword,
};
