import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { z } from "zod";

const SingleSelectSchema = z.object({
  option: z.string().refine((args) => args !== "", {
    params: { i18n: "select_one" },
  }),
});
const MultiSelectSchema = z.object({
  option: z
    .string()
    .array()
    .refine((args) => args.length !== 0, { params: { i18n: "select_one" } }),
});
const TextSchema = z.object({
  text: z.coerce.string().refine((args) => args !== "", {
    params: { i18n: "fill_field" },
  }),
});

export type QuizzQuestionOptions = {
  value: string;
  label: string;
};

export type QuizzQuestionCustom = {
  single: boolean;
};

export type QuizzQuestionProps = {
  type: string;
  title: string;
  options?: QuizzQuestionOptions[];
  custom?: QuizzQuestionCustom;
  onSend: SubmitHandler<FieldValues>;
  isSending?: boolean;
};

const QuizzQuestion = ({
  type,
  title,
  options,
  custom = { single: true },
  onSend,
  isSending,
}: QuizzQuestionProps) => {
  const isSelectAndHasOptions = type === "select" && options;
  const isSelectMulti = isSelectAndHasOptions && custom?.single === false;
  const isText = type === "text";

  const resolver =
    !isSelectMulti && !isText
      ? zodResolver(SingleSelectSchema)
      : isSelectMulti && !isText
        ? zodResolver(MultiSelectSchema)
        : zodResolver(TextSchema);

  const { register, handleSubmit, formState } = useForm({
    defaultValues:
      !isSelectMulti && !isText
        ? {
            option: "",
          }
        : isSelectMulti && !isText
          ? {
              option: [],
            }
          : {
              text: "",
            },
    shouldFocusError: true,
    resolver,
    mode: "onChange",
  });

  return (
    <Card
      elevation={6}
      sx={{
        width: "100%",
        height: {
          xs: "320px",
          xl: "400px",
        },
        borderRadius: 6,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      component="form"
      onSubmit={handleSubmit(onSend)}
    >
      <CardContent
        sx={{
          p: 3,
          pb: 1,
          overflowY: "auto",
          flex: 1,
        }}
      >
        <FormControl
          error={!!formState.errors.text || !!formState.errors.option}
          sx={{
            width: "100%",
          }}
        >
          <FormLabel
            sx={{
              mb: 1.5,
            }}
          >
            {title}
          </FormLabel>
          {isSelectAndHasOptions && !isSelectMulti && (
            <>
              <RadioGroup>
                {options.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio size="small" {...register("option")} />}
                    label={option.label}
                    disabled={isSending}
                  />
                ))}
              </RadioGroup>
              <FormHelperText>
                {formState.errors.option?.message?.toString() ?? ""}
              </FormHelperText>
            </>
          )}
          {isSelectAndHasOptions && isSelectMulti && (
            <>
              <FormGroup>
                {options.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Checkbox size="small" {...register("option")} />}
                    label={option.label}
                    disabled={isSending}
                  />
                ))}
              </FormGroup>
              <FormHelperText>
                {formState.errors.option?.message?.toString() ?? ""}
              </FormHelperText>
            </>
          )}
          {isText && (
            <>
              <TextField
                multiline={!custom?.single}
                rows={custom?.single ? 1 : 5}
                fullWidth
                disabled={isSending}
                {...register("text")}
              />
              <FormHelperText>
                {formState.errors.text?.message?.toString() ?? ""}
              </FormHelperText>
            </>
          )}
        </FormControl>
      </CardContent>
      <Divider
        sx={{
          pb: 0,
        }}
      />
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 0,
          height: "48px",
        }}
      >
        <Button
          type="submit"
          variant="text"
          disabled={isSending}
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          {isSending ? (
            <CircularProgress sx={{ mr: 1 }} size={16} />
          ) : (
            <Trans>Send</Trans>
          )}
        </Button>
      </CardActions>
    </Card>
  );
};

export default QuizzQuestion;
