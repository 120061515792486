export type ParametersObject = Record<
  string,
  | string
  | string[]
  | number
  | number[]
  | undefined
  | null
  | boolean
  | boolean[]
  | never
>;

const stringfyParams = (params: ParametersObject) => {
  const urlParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null && value !== "") {
      if (Array.isArray(value)) {
        for (const v of value) {
          urlParams.append(key, v.toString());
        }
      } else {
        urlParams.append(key, value.toString());
      }
    }
  });
  return urlParams.toString();
};

export default stringfyParams;
