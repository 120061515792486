import LockIcon from "@mui/icons-material/Lock";
import { Button } from "@mui/material";
import React from "react";

import BackButtonLink from "../../BackButtonLink";

import ErrorPage from "./ErrorPage";

const Page403: React.FC<{ onReset?: () => void }> = ({ onReset }) => {
  return (
    <ErrorPage
      icon={LockIcon}
      title="403 - Forbidden"
      subtitle="Sorry, you don't have permission to access this page."
      actions={
        onReset && (
          <>
            <Button variant="contained" onClick={onReset}>
              Try reset
            </Button>
            or
            <BackButtonLink variant="contained" onClick={onReset} />
          </>
        )
      }
    />
  );
};

export default Page403;
