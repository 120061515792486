import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List as MuiList,
  Stack,
  SxProps,
  ThemeProvider,
  Tooltip,
  TooltipProps,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, PathPattern, To, useMatch } from "react-router-dom";

import { MenuRootContext } from "./MenuContext";

import PiniOnOneLogoWhiteSM from "@/assets/pinionone-logo-white-sm.png";
import PiniOnOneLogoWhiteSM2x from "@/assets/pinionone-logo-white-sm@2x.png";
import PiniOnOneLogoWhite from "@/assets/pinionone-logo-white.png";
import PiniOnOneLogoWhite2x from "@/assets/pinionone-logo-white@2x.png";
import getTheme from "@/theme";

export const MenuRootContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const fullScreen = useMediaQuery("(max-width:1023px)");
  const [open, setOpen] = useState(!fullScreen);

  return (
    <MenuRootContext.Provider
      value={{
        open,
        toggleOpen: () => setOpen((open) => !open),
        close: () => setOpen(false),
      }}
    >
      {children}
    </MenuRootContext.Provider>
  );
};

const MenuDrawer = ({ children }: React.PropsWithChildren) => {
  const { open: menuOpen } = useContext(MenuRootContext);

  return (
    <>
      <Box
        sx={{
          "@media (min-width: 1920px)": {
            width: menuOpen ? "360px" : "80px",
          },
          "@media (min-width: 1440px) and (max-width: 1919px)": {
            width: menuOpen ? "300px" : "80px",
          },
          "@media (min-width: 1024px) and (max-width: 1439px)": {
            width: menuOpen ? "200px" : "80px",
          },
          "@media (max-width: 1023px)": {
            width: menuOpen ? "100vw" : "0px",
            position: "fixed",
            zIndex: 9999,
          },
          height: "100vh",
          backgroundColor: "#1F1F24",
          borderRight: (theme) => `1px solid ${theme.palette.divider}`,
          transition: "width 0.2s",
          overflowY: "auto",
          colorScheme: "dark",
        }}
      >
        {children}
      </Box>
    </>
  );
};

const darkTheme = getTheme("dark");

const Root = ({ children }: React.PropsWithChildren) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <MenuDrawer>{children}</MenuDrawer>
    </ThemeProvider>
  );
};

const Header = () => {
  const { t } = useTranslation();
  const { open: menuOpen, toggleOpen } = useContext(MenuRootContext);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      p={2.5}
      px={2.6}
      height="80px"
    >
      <img
        src={menuOpen ? PiniOnOneLogoWhite : PiniOnOneLogoWhiteSM}
        srcSet={`${
          menuOpen ? PiniOnOneLogoWhite2x : PiniOnOneLogoWhiteSM2x
        } 2x`}
        alt="PiniOn One Logo White"
        loading="lazy"
        height="32px"
      />
      {menuOpen && (
        <Tooltip title={t("Close")}>
          <IconButton
            sx={{
              borderRadius: "5px",
              ":hover": {
                backgroundColor: "#2B2B30",
              },
            }}
            onClick={toggleOpen}
          >
            <CloseIcon sx={{ color: grey[50] }} />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};

type SectionType = {
  title?: string;
  divider?: boolean;
};
const Section = ({
  title,
  divider,
  children,
}: React.PropsWithChildren<SectionType>) => {
  const { open: menuOpen } = useContext(MenuRootContext);

  return (
    <>
      {title && menuOpen && (
        <Box sx={{ padding: "0 20px", mb: "5px", color: grey[400] }}>
          <Typography fontWeight="500">{title}</Typography>
        </Box>
      )}
      {children}
      {divider && (
        <Divider sx={{ borderColor: "#3E3E41", margin: "24px 20px" }} />
      )}
    </>
  );
};

const List = ({ children }: React.PropsWithChildren) => {
  return (
    <MuiList
      sx={{
        padding: "0 12px",
        display: "flex",
        flexDirection: "column",
        gap: "2px",
      }}
    >
      {children}
    </MuiList>
  );
};

type WithTooltipType = {
  condition: boolean;
} & TooltipProps;
const WithTooltip = ({ condition, children, ...tooltip }: WithTooltipType) => {
  return condition ? (
    <Tooltip {...tooltip}>{children}</Tooltip>
  ) : (
    <>{children}</>
  );
};

type ItemType = {
  label: string;
  icon?: React.ReactNode;
  onClick?: () => void;
};
const Item = ({ label, icon, onClick }: ItemType) => {
  const { open: menuOpen } = useContext(MenuRootContext);

  return (
    <ListItem disablePadding sx={{ display: "block" }}>
      <WithTooltip condition={!menuOpen} title={label} placement="right">
        <ListItemButton
          sx={{
            display: "flex",
            justifyContent: menuOpen ? "start" : "center",
            width: "100%",
            height: "48px",
            borderRadius: "16px",
            ":hover": {
              backgroundColor: "#2B2B30",
            },
          }}
          onClick={onClick}
        >
          {icon && (
            <ListItemIcon
              sx={{
                color: grey[400],
                minWidth: "initial",
                mr: menuOpen ? "15px" : 0,
              }}
            >
              {icon}
            </ListItemIcon>
          )}
          {menuOpen && (
            <ListItemText sx={{ color: grey[400] }} primary={label} />
          )}
        </ListItemButton>
      </WithTooltip>
    </ListItem>
  );
};

const EmptyItem = ({ children }: React.PropsWithChildren) => {
  const { open: menuOpen } = useContext(MenuRootContext);

  if (!menuOpen) return null;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "48px",
        userSelect: "none",
      }}
    >
      <Typography sx={{ color: grey[400], fontSize: "14px" }}>
        {children}
      </Typography>
    </Box>
  );
};

const listItemStyle: SxProps = {
  color: grey[400],
  "& .MuiTypography-root": {
    fontSize: "16px",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  "@media (min-width: 1440px) and (max-width: 1919px)": {
    "& .MuiTypography-root": {
      fontSize: "14px",
    },
  },
  "@media (min-width: 1024px) and (max-width: 1439px)": {
    "& .MuiTypography-root": {
      fontSize: "12px",
    },
  },
};

type NavItemType = {
  label: string;
  to: To;
  subitem?: boolean;
  icon?: React.ReactNode;
};
const NavItem = ({ label, to, subitem, icon }: NavItemType) => {
  const { open: menuOpen, close } = useContext(MenuRootContext);

  return (
    <ListItem
      component={NavLink}
      to={to}
      disablePadding
      onClick={close}
      sx={{
        display: "block",
        "&.active .MuiButtonBase-root": {
          backgroundColor: subitem ? "none" : "#2B2B30",
        },
        "&.active .MuiListItemText-root": {
          color: subitem ? "#fff" : "none",
        },
        "&.active .MuiSvgIcon-root": {
          color: subitem ? "#fff" : "#0E5ED1",
        },
      }}
    >
      <WithTooltip condition={!menuOpen} title={label} placement="right">
        <ListItemButton
          sx={{
            display: "flex",
            justifyContent: menuOpen ? "start" : "center",
            width: "100%",
            height: "48px",
            borderRadius: "16px",
            backgroundColor: "transparent",
            color: "#1F1F24",
            ":hover": {
              backgroundColor: "#2B2B30",
            },
          }}
        >
          {icon && (
            <ListItemIcon
              sx={{
                color: grey[400],
                minWidth: "initial",
                mr: menuOpen ? "15px" : 0,
                ".MuiSvgIcon-root": {
                  fontSize: !subitem ? "24px" : menuOpen ? "20px" : "16px",
                },
              }}
            >
              {icon}
            </ListItemIcon>
          )}
          {menuOpen && <ListItemText sx={listItemStyle} primary={label} />}
        </ListItemButton>
      </WithTooltip>
    </ListItem>
  );
};

type CollapsibleItemType = {
  label: string;
  selectTo?: string | PathPattern<string>;
  icon?: React.ReactNode;
};
const CollapsibleItem = ({
  label,
  selectTo,
  icon,
  children,
}: React.PropsWithChildren<CollapsibleItemType>) => {
  const { open: menuOpen } = useContext(MenuRootContext);
  const [open, setOpen] = useState(false);
  const match = useMatch(selectTo ?? "");

  return (
    <ListItem
      disablePadding
      sx={{
        display: "block",
      }}
    >
      <WithTooltip condition={!menuOpen} title={label} placement="right">
        <ListItemButton
          sx={{
            display: "flex",
            justifyContent: menuOpen ? "start" : "center",
            width: "100%",
            height: "48px",
            borderRadius: "16px",
            backgroundColor: match ? "#2B2B30" : "transparent",
            ":hover": {
              backgroundColor: "#2B2B30",
            },
          }}
          onClick={() => setOpen(!open)}
        >
          {icon && (
            <ListItemIcon
              sx={{
                color: match ? "#0E5ED1" : grey[400],
                minWidth: "initial",
                mr: menuOpen ? "15px" : 0,
                ".MuiSvgIcon-root": {
                  fontSize: "24px",
                },
              }}
            >
              {icon}
            </ListItemIcon>
          )}
          {menuOpen && <ListItemText sx={listItemStyle} primary={label} />}
          {menuOpen &&
            (open ? (
              <KeyboardArrowDownIcon sx={{ color: grey[400] }} />
            ) : (
              <KeyboardArrowRightIcon sx={{ color: grey[400] }} />
            ))}
        </ListItemButton>
      </WithTooltip>
      <Collapse
        in={open}
        unmountOnExit
        sx={{
          marginTop: "2px",
          "& .MuiList-root": {
            paddingRight: 0,
            paddingLeft: menuOpen ? "15px" : 0,
          },
        }}
      >
        {children}
      </Collapse>
    </ListItem>
  );
};

export default {
  Root,
  Header,
  Section,
  List,
  Item,
  EmptyItem,
  NavItem,
  CollapsibleItem,
};
