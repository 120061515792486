import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CategoryIcon from "@mui/icons-material/Category";
import ExtensionIcon from "@mui/icons-material/Extension";
import ForumIcon from "@mui/icons-material/Forum";
import HomeIcon from "@mui/icons-material/Home";
import LanguageIcon from "@mui/icons-material/LanguageRounded";
import LeaderboardIcon from "@mui/icons-material/LeaderboardRounded";
import NoteAddIcon from "@mui/icons-material/NoteAddRounded";
import PeopleIcon from "@mui/icons-material/People";
import QuizIcon from "@mui/icons-material/Quiz";
import StorageIcon from "@mui/icons-material/StorageRounded";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import WorkIcon from "@mui/icons-material/Work";
import { Skeleton, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

import Menu from "../menu/Menu";

import { useAuth } from "@/modules/auth/AuthContext";

const solutionMap = {
  compass: {
    label: "Compasses",
    path: "compasses",
    icon: <LanguageIcon />,
  },
  dashboards: {
    label: "Dashboards",
    path: "dashboards",
    icon: <LeaderboardIcon />,
  },
  file: {
    label: "Files",
    path: "files",
    icon: <StorageIcon />,
  },
  requests: {
    label: "Requests",
    path: "requests",
    icon: <NoteAddIcon />,
  },
};

const adminMenuItems = [
  {
    label: "Solutions",
    path: "solutions",
    icon: <CategoryIcon />,
  },
  {
    label: "Products",
    path: "products",
    icon: <ExtensionIcon />,
  },
  {
    label: "Banners",
    path: "banners",
    icon: <ViewCarouselIcon />,
  },
  {
    label: "Quizzes",
    path: "quizzes",
    icon: <QuizIcon />,
  },
  {
    label: "Customers",
    path: "customers",
    icon: <WorkIcon />,
  },
  {
    label: "Modules",
    path: "modules",
    icon: <ViewModuleIcon />,
  },
  {
    label: "Users",
    path: "users",
    icon: <PeopleIcon />,
  },
];

const MenuSkeleton = () => {
  return (
    <Stack sx={{ px: "16px" }}>
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
        sx={{ px: "10px", height: "48px" }}
      >
        <Skeleton
          variant="circular"
          width={30}
          height={24}
          sx={{ backgroundColor: "grey.500" }}
        />
        <Skeleton
          variant="rectangular"
          width={260}
          height={20}
          sx={{ backgroundColor: "grey.500" }}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
        sx={{ px: "10px", height: "48px" }}
      >
        <Skeleton
          variant="circular"
          width={30}
          height={24}
          sx={{ backgroundColor: "grey.500" }}
        />
        <Skeleton
          variant="rectangular"
          width={260}
          height={20}
          sx={{ backgroundColor: "grey.500" }}
        />
      </Stack>
    </Stack>
  );
};

export const NavBar = () => {
  const { t } = useTranslation();
  const { user, adminRoles, isFetchingMe } = useAuth();

  const handleContact = () => {
    const mailto = "mailto:comercial@pinion.app";
    window.open(mailto, "_blank");
  };

  return (
    <Menu.Root>
      <Menu.Header />
      <Menu.Section divider>
        <Menu.List>
          <Menu.NavItem icon={<HomeIcon />} label={t("Home")} to="/" />
          {user?.is_admin && (
            <Menu.CollapsibleItem
              key="admin"
              label={t("Admin")}
              selectTo={`/admin/*`}
              icon={<AdminPanelSettingsIcon />}
            >
              <Menu.List>
                {adminMenuItems.map((ami) => {
                  if (!adminRoles?.roles.includes(`admin::${ami.path}`)) {
                    return null;
                  }
                  return (
                    <Menu.NavItem
                      key={ami.label}
                      label={t(ami.label)}
                      to={`/admin/${ami.path}`}
                      icon={ami.icon}
                      subitem
                    />
                  );
                })}
              </Menu.List>
            </Menu.CollapsibleItem>
          )}
        </Menu.List>
      </Menu.Section>
      <Menu.Section title={t("My solutions")} divider>
        {isFetchingMe ? (
          <MenuSkeleton />
        ) : (
          user?.solutions && (
            <Menu.List>
              {user?.solutions.map((solution) => (
                <Menu.CollapsibleItem
                  key={solution.id}
                  label={solution.name}
                  selectTo={`/solutions/${solution.id}/*`}
                  icon={<CategoryIcon />}
                >
                  {solution.types && (
                    <Menu.List>
                      {solution.types.map((item, index) => (
                        <Menu.NavItem
                          key={index}
                          label={solutionMap[item].label}
                          icon={solutionMap[item].icon}
                          to={`/solutions/${solution.id}/${solutionMap[item].path}`}
                          subitem
                        />
                      ))}
                    </Menu.List>
                  )}
                </Menu.CollapsibleItem>
              ))}
            </Menu.List>
          )
        )}
      </Menu.Section>
      <Menu.Section title={t("Service")}>
        <Menu.List>
          <Menu.Item
            icon={<ForumIcon />}
            label={t("Contact consulting")}
            onClick={handleContact}
          />
        </Menu.List>
      </Menu.Section>
    </Menu.Root>
  );
};
