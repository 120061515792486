import { LinearProgress, Stack, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";

import { MenuRootContextProvider } from "../menu/Menu";

import { NavBar } from "./NavBar";
import { Topbar } from "./Topbar";

const AppLayout = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        "@media (max-width: 1023px)": {
          display: "flex",
          flexDirection: "column",
        },
        height: "100vh",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <MenuRootContextProvider>
        <NavBar />
        <Stack>
          <Topbar />
          <Box sx={{ height: "calc(100vh - 65px)", overflowY: "auto" }}>
            <Suspense fallback={<LinearProgress sx={{ width: "100%" }} />}>
              <Outlet />
            </Suspense>
          </Box>
        </Stack>
      </MenuRootContextProvider>
    </Box>
  );
};

export const AppLayoutSkeleton = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: {
            xs: "100vh",
            sm: "calc(100vh - 65px)",
          },
        }}
      >
        <LinearProgress sx={{ width: "100%" }} />
      </Box>
    </>
  );
};

export default AppLayout;
