import "./global.css";

import "@fontsource/roboto/latin-300.css";
import "@fontsource/roboto/latin-400.css";
import "@fontsource/roboto/latin-500.css";
import "@fontsource/roboto/latin-700.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "dayjs/locale/en";
import "mapbox-gl/dist/mapbox-gl.css";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import updateLocale from "dayjs/plugin/updateLocale";
import weekday from "dayjs/plugin/weekday";
import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";

import { AppQueryClient } from "./appQueryClient";
import { config } from "./config";
import { i18n } from "./i18n";
import { AuthProvider } from "./modules/auth/AuthContext";
import { ErrorFallback } from "./modules/common/components/ErrorFallback";
import AppRouter from "./router/AppRouter";
import getTheme, { SupportedLocales, useThemeModeStore } from "./theme";

import { ToastProvider } from "@/modules/common/lib/toast";

dayjs.extend(isBetween);
dayjs.extend(weekday);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1,
});
dayjs.locale("en");

Sentry.init({
  dsn: config.sentry.dsn,
  environment: config.enviroment,
  enabled: !!config.sentry.dsn,
  release: `pinion.one@${config.version}`,
  ignoreErrors: [
    /Failed to fetch dynamically imported module/,
    /Non-Error exception captured with keys: message, status/,
  ],
});

const SentryBoundary = ({ children }: { children: React.ReactNode }) => {
  if (config.enviroment === "local") {
    return <>{children}</>;
  }

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

const locale = i18n.language.replace("-", "") as SupportedLocales;

function App() {
  const { mode } = useThemeModeStore();
  const theme = React.useMemo(() => {
    const theme = getTheme(mode, locale);
    document.body.style.colorScheme = mode;
    return theme;
  }, [mode]);
  return (
    <React.StrictMode>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
        <I18nextProvider i18n={i18n} defaultNS={"translation"}>
          <ThemeProvider theme={theme}>
            <ToastProvider>
              <SentryBoundary>
                <AppQueryClient>
                  <AuthProvider>
                    <CssBaseline />
                    <AppRouter />
                  </AuthProvider>
                </AppQueryClient>
              </SentryBoundary>
            </ToastProvider>
          </ThemeProvider>
        </I18nextProvider>
      </LocalizationProvider>
    </React.StrictMode>
  );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <App />
);
