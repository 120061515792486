import { Box, Stack } from "@mui/material";

import LinkedInSmallIcon from "@/assets/linkedin-small-icon.png";
import LinkedInSmallIcon2x from "@/assets/linkedin-small-icon@2x.png";
import PiniOnLogoSmallIcon from "@/assets/pinion-logo-small-icon.png";
import PiniOnLogoSmallIcon2x from "@/assets/pinion-logo-small-icon@2x.png";
import PiniOnLogo from "@/assets/pinion-logo.png";
import PiniOnLogo2x from "@/assets/pinion-logo@2x.png";

const PinionFooter = () => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      mt="auto"
      pt={4}
      borderTop={(theme) => `1px solid ${theme.palette.divider}`}
    >
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src={PiniOnLogo}
          srcSet={`${PiniOnLogo2x} 2x`}
          height={24}
        />
      </Box>
      <Box>
        <Stack direction="row" alignItems="end" gap={2}>
          <a
            href="https://pinion.app/"
            target="_blank"
            style={{ display: "inherit" }}
            rel="noreferrer"
          >
            <img
              src={PiniOnLogoSmallIcon}
              srcSet={`${PiniOnLogoSmallIcon2x} 2x`}
              alt="PiniOn Logo Icon"
              loading="lazy"
              height="20px"
            />
          </a>
          <a
            href="https://br.linkedin.com/company/pinionapp"
            target="_blank"
            style={{ display: "inherit" }}
            rel="noreferrer"
          >
            <img
              src={LinkedInSmallIcon}
              srcSet={`${LinkedInSmallIcon2x} 2x`}
              alt="LinkedIn Logo Icon"
              loading="lazy"
              height="20px"
            />
          </a>
        </Stack>
      </Box>
    </Stack>
  );
};

export default PinionFooter;
