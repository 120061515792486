import { Box, Skeleton, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect } from "react";

import PinionFooter from "../../PinionFooter";

type Props = {
  children?: React.ReactNode;
  actions?: React.ReactNode;
  pageId?: string;
  title?: string;
  icon?: React.ElementType;
  disablePadding?: boolean;
  isLoading?: boolean;
  sx?: React.CSSProperties;
};

const TitledPage = ({
  children,
  actions,
  title,
  icon: Icon,
  disablePadding,
  pageId,
  isLoading,
  sx,
}: Props) => {
  useEffect(() => {
    if (title) {
      document.title = `${title} | Pinion One`;
    }
  }, [title]);

  return (
    <Box
      sx={{
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        py: disablePadding ? 0 : 4,
        px: disablePadding ? 0 : 6,
        gap: 3,
        bgcolor: (theme) => theme.palette.background.default,
        position: "relative",
        ...sx,
      }}
      id={pageId}
    >
      <Stack
        justifyContent="space-between"
        sx={{ flexDirection: { xs: "column", md: "row" }, gap: { xs: 2 } }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          {isLoading ? (
            <Skeleton variant="circular" width={30} height={30} />
          ) : (
            Icon && <Icon style={{ fontSize: 30 }} />
          )}
          <Typography
            variant="h5"
            component="h2"
            sx={{
              overflowWrap: "anywhere",
              fontSize: "30px",
              fontWeight: "bold",
            }}
          >
            {isLoading ? <Skeleton width={200} /> : title}
          </Typography>
        </Stack>
        <Stack
          sx={{
            flexDirection: { xs: "column", sm: "row" },
            gap: 1,
            alignItems: { xs: "initial", sm: "center" },
            flex: 1,
            justifyContent: { xs: "initial", sm: "flex-end" },
          }}
        >
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              height={40}
              sx={{
                width: { xs: "100%", sm: 160 },
              }}
            />
          ) : (
            actions
          )}
        </Stack>
      </Stack>
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          sx={{
            height: {
              xs: "calc(100vh - 225px)",
              sm: "calc(100vh - 178px)",
            },
          }}
        />
      ) : (
        children
      )}
      <PinionFooter />
    </Box>
  );
};

export default TitledPage;
