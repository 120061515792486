import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { Button } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import React from "react";

import { FetcherError } from "../../lib/httpClient/fetcher";
import ErrorPage from "../layout/pages/ErrorPage";
import Page403 from "../layout/pages/Page403";
import Page404 from "../layout/pages/Page404";

import BackButtonLink from "@/modules/common/components/BackButtonLink";

type State = {
  error: Error | FetcherError | null;
};

type Props = {
  children: React.ReactNode;
  resetQuery: () => void;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  resetError = () => {
    this.setState({ error: null });
    this.props.resetQuery();
  };

  componentDidCatch(
    error: Error | FetcherError,
    { componentStack }: React.ErrorInfo
  ) {
    if (!(error as FetcherError).status) {
      Sentry.withScope(() => {
        Sentry.captureException(error, {
          contexts: { react: { componentStack } },
        });
      });
    }
    this.setState({ error });
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.children !== this.props.children) {
      this.resetError();
    }
  }

  render() {
    if (this.state.error) {
      switch ((this.state.error as FetcherError).status) {
        case 404:
          return <Page404 onReset={this.resetError} />;
        case 403:
          return <Page403 onReset={this.resetError} />;
        default:
          return (
            <PageUnknownError
              error={this.state.error}
              onReset={this.resetError}
            />
          );
      }
    }

    return this.props.children;
  }
}

const PageUnknownError: React.FC<{
  error: FetcherError | (Error & { status?: undefined });
  onReset?: () => void;
}> = ({ error, onReset }) => {
  return (
    <ErrorPage
      icon={ReportGmailerrorredIcon}
      title={error.status ? `${error.status} - ${error.message}` : undefined}
      actions={
        <>
          <Button variant="contained" onClick={onReset}>
            Try reset
          </Button>
          or
          <BackButtonLink variant="contained" onClick={onReset} />
        </>
      }
    />
  );
};

export default function QueryErrorBoundary(props: {
  children: React.ReactNode;
}) {
  const { reset } = useQueryErrorResetBoundary();
  return <ErrorBoundary {...props} resetQuery={reset} />;
}
