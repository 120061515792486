import httpClient from "../common/lib/httpClient";

export type AdminRolesResponse = {
  roles: string[];
};

const getAdminRoles = async (): Promise<AdminRolesResponse> =>
  httpClient.get(`/admin`);

export default { getAdminRoles };
