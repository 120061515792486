import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { Button } from "@mui/material";
import React from "react";

import BackButtonLink from "../../BackButtonLink";

import ErrorPage from "./ErrorPage";

const Page404: React.FC<{ onReset?: () => void }> = ({ onReset }) => {
  return (
    <ErrorPage
      icon={SentimentVeryDissatisfiedIcon}
      title="404 - Page Not Found"
      subtitle="We're sorry, the page you requested could not be found."
      actions={
        onReset && (
          <>
            <Button variant="contained" onClick={onReset}>
              Try reset
            </Button>
            or
            <BackButtonLink variant="contained" onClick={onReset} />
          </>
        )
      }
    />
  );
};

export default Page404;
