import {
  Box,
  Card,
  CardContent,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import Lottie from "lottie-react";
import { useCallback, useMemo } from "react";
import { FieldValues } from "react-hook-form";
import { Trans } from "react-i18next";

import emptyQuizz from "../../assets/empty-quizz.svg";
import successfullySend from "../../assets/successfully-send.json";
import HomeRequests from "../../Home";

import QuizzQuestion from "./QuizzQuestion";

const QuizzEmpty = () => {
  return (
    <Card
      sx={{
        width: "100%",
        height: {
          xs: "320px",
          xl: "400px",
        },
        borderRadius: 6,
      }}
      elevation={6}
    >
      <CardContent sx={{ height: "100%" }}>
        <Stack height="100%" justifyContent="center" alignItems="center">
          <img src={emptyQuizz} />
          <Stack mt={2} alignItems="center" gap={1}>
            <Typography variant="body2" textAlign="center">
              <Trans>Currently, there are no questions available.</Trans>
            </Typography>
            <Typography variant="body2">
              <Trans>Thank you for participating!</Trans>
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

const QuizzSent = () => {
  return (
    <Card
      sx={{
        width: "100%",
        height: {
          xs: "320px",
          xl: "400px",
        },
        borderRadius: 6,
      }}
      elevation={6}
    >
      <CardContent sx={{ height: "100%" }}>
        <Stack height="100%" justifyContent="center" alignItems="center">
          <Box sx={{ width: "100px", height: "100px" }}>
            <Lottie animationData={successfullySend} loop={false} />
          </Box>
          <Stack mt={2} alignItems="center" gap={1}>
            <Typography variant="body2" textAlign="center" fontWeight="bold">
              <Trans>Your response was sent successfully.</Trans>
            </Typography>
            <Typography variant="body2" fontWeight="bold">
              <Trans>Thank you for participating!</Trans>
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

const Quizz = () => {
  const { data, isFetching: isLoadingQuizz } = useQuery({
    queryKey: ["quizz"],
    queryFn: () => HomeRequests.getQuizz(),
    throwOnError: false,
  });

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: HomeRequests.answer,
    onSuccess: () => {
      return;
    },
  });

  const handleSendQuizz = useCallback(
    (ans: FieldValues) => {
      return mutateAsync({
        id: data?.id ?? "",
        data: ans.option ? { answer: ans.option } : { answer: ans.text },
      });
    },
    [data, mutateAsync]
  );

  const { options = [], title = "" } = useMemo<{
    options?: { label: string; value: string }[];
    title?: string;
  }>(() => {
    if (!data?.type) {
      return {};
    }
    const translationKey = Object.keys(data?.translations).find(
      (t) => t.split("-")[0] === i18next.language.split("-")[0]
    );
    const translation =
      data.translations[translationKey ?? "en"] ?? data.translations["en"];

    return {
      options:
        translation.options?.map((o) => ({
          label: o,
          value: o,
        })) ?? [],
      title: translation.title,
    };
  }, [data]);

  if (isLoadingQuizz) {
    return (
      <Card
        sx={{
          width: "100%",
          height: {
            xs: "320px",
            xl: "400px",
          },
          borderRadius: 6,
        }}
        elevation={6}
      >
        <Skeleton
          variant="rectangular"
          sx={{ backgroundColor: "grey.500" }}
          width="100%"
          height="100%"
        />
      </Card>
    );
  }

  if (!data?.type) {
    return <QuizzEmpty />;
  }

  if (isSuccess) {
    return <QuizzSent />;
  }

  return (
    <QuizzQuestion
      type={data?.type}
      custom={data?.custom}
      title={title}
      options={options}
      onSend={handleSendQuizz}
      isSending={isPending}
    />
  );
};

export default Quizz;
