import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Paper,
  LinearProgress,
  Box,
  IconButton,
  Stack,
  Typography,
  Tooltip,
  ThemeProvider,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { Trans } from "react-i18next";

import getTheme from "@/theme";

function usePausableTimerWithProgressTracker(
  callback: () => void,
  initialTime = 6000,
  tickFrequency = 250
) {
  const [progress, setProgress] = useState(initialTime);
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    if (!isPlaying) {
      return;
    }
    if (!progress) {
      callback();
      setProgress(initialTime);
      return;
    }
    const progress_timer = setTimeout(
      () => {
        setProgress((progress) => progress - tickFrequency);
      },
      progress === initialTime ? tickFrequency * 2 : tickFrequency
    );
    return () => {
      clearInterval(progress_timer);
    };
  }, [progress, tickFrequency, callback, initialTime, isPlaying]);

  const formatMillisToPercentage = (milliseconds: number) => {
    return Math.floor((milliseconds / initialTime) * 100);
  };

  return {
    progress,
    percentage: formatMillisToPercentage(progress),
    pause: () => {
      setIsPlaying(false);
      setProgress(initialTime);
    },
    resume: () => {
      setIsPlaying(true);
    },
  };
}

const Carousel = ({
  items,
}: {
  items: Omit<CarouselRootProps, "activeStep">[];
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = useCallback(() => {
    if (activeStep === items.length - 1) {
      setActiveStep(0);
      return;
    }
    setActiveStep((prev) => prev + 1);
  }, [activeStep, items.length]);

  const handleBack = () => {
    if (activeStep === 0) {
      setActiveStep(items.length - 1);
      return;
    }
    setActiveStep((prev) => prev - 1);
  };

  const { pause, resume, percentage } =
    usePausableTimerWithProgressTracker(handleNext);

  return (
    <Paper
      sx={{
        display: "flex",
        flexWrap: "nowrap",
        overflow: "hidden",
        position: "relative",
        borderRadius: 6,
      }}
      onMouseEnter={pause}
      onMouseLeave={resume}
    >
      {items?.map((item) => (
        <CarouselItem
          key={item.title}
          title={item.title}
          description={item.description}
          imgPath={item.imgPath}
          onClick={item.onClick}
          activeStep={activeStep}
        />
      ))}
      <LinearProgress
        variant="determinate"
        value={percentage}
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          opacity: 0.9,
          backgroundColor: "transparent",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          bottom: 24,
          p: 1,
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Stack
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "5px",
          }}
        >
          {items.map((_, index) => (
            <Box
              key={index}
              sx={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                bgcolor: (theme) => theme.palette.background.default,
                opacity: activeStep === index ? 1 : 0.5,
                transition: "0.5s cubic-bezier(0.075, 0.82, 0.165, 1)",
                cursor: "pointer",
              }}
              onClick={() => setActiveStep(index)}
            />
          ))}
        </Stack>
      </Box>
      <Box position="absolute" bottom={32} right={32}>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Tooltip title={<Trans>See previous slide</Trans>}>
            <IconButton
              sx={{
                bgcolor: (theme) => theme.palette.background.default,
                ":hover": {
                  bgcolor: (theme) => theme.palette.background.default,
                },
              }}
              onClick={handleBack}
            >
              <NavigateBeforeIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={<Trans>See next slide</Trans>}>
            <IconButton
              sx={{
                bgcolor: (theme) => theme.palette.background.default,
                ":hover": {
                  bgcolor: (theme) => theme.palette.background.default,
                },
              }}
              onClick={handleNext}
            >
              <NavigateNextIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Paper>
  );
};

type CarouselRootProps = {
  title?: string;
  description?: string;
  imgPath: string;
  activeStep: number;
  onClick?: () => void;
};

const CarouselItem = ({
  title,
  description,
  imgPath,
  activeStep,
  onClick,
}: CarouselRootProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: {
          xs: "320px",
          xl: "400px",
        },
        backgroundImage: `url(${imgPath})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        cursor: "pointer",
        minWidth: "100%",
        width: "100%",
        transition: "1s cubic-bezier(0.075, 0.82, 0.165, 1)",
        transform: `translateX(-${activeStep * 100}%)`,
      }}
      onClick={onClick}
    >
      <Stack
        sx={{
          flex: 1,
          background:
            "linear-gradient(90deg, rgba(0,0,0,0.7) 5%, rgba(0,0,0,0.3) 75%, rgba(0,0,0,0.1) 100%)",
        }}
      >
        <Box
          sx={{
            p: 2,
            pl: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
            width: "80%",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: "28px",
              fontWeight: "bold",
            }}
          >
            {title}
          </Typography>
          <Typography variant="body1" color="white">
            {description}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

const lightTheme = getTheme("light");

const CarouselWrapper = ({
  items,
}: {
  items: Omit<CarouselRootProps, "activeStep">[];
}) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <Carousel items={items} />
    </ThemeProvider>
  );
};

export default CarouselWrapper;
