import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { z } from "zod";
import { makeZodI18nMap } from "zod-i18n-map";
import zodEN from "zod-i18n-map/locales/en/zod.json";
import zodPT from "zod-i18n-map/locales/pt/zod.json";

import { config } from "./config";

i18next
  .use(Backend)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    detection: {
      order: ["navigator"],
    },
    load: "languageOnly",
    debug: config.enviroment === "local",
    partialBundledLanguages: true,
    resources: {
      en: {
        zod: zodEN,
        custom: {
          fill_field: "Fill the field",
          select_one: "Select at least one option",
        },
      },
      pt: {
        zod: zodPT,
        custom: {
          fill_field: "Preencha o campo",
          select_one: "Selecione ao menos uma opção",
        },
      },
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
  });

z.setErrorMap(makeZodI18nMap({ ns: ["zod", "custom"] }));

export const i18n = i18next;
