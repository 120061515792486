import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { To } from "react-router-dom";

import ButtonLink, { ButtonLinkProps } from "./ButtonLink";

const BackButtonLink = (props: Omit<ButtonLinkProps, "to"> & { to?: To }) => {
  return (
    <ButtonLink to={-1 as To} sx={{ gap: 1 }} {...props}>
      <ArrowBackIcon style={{ fontSize: 16 }} /> Back
    </ButtonLink>
  );
};

export default BackButtonLink;
