function pausableTimer(callback: () => void, delay: number) {
  let timerId: number | null = null;
  let start: number;
  let remaining = delay;

  const pause = () => {
    window.clearTimeout(timerId as number);
    timerId = null;
    remaining -= Date.now() - start;
  };

  const resume = () => {
    if (timerId) {
      return;
    }
    start = Date.now();
    timerId = window.setTimeout(callback, remaining);
  };

  resume();

  return {
    pause,
    resume,
  };
}

export default pausableTimer;
