export const config = {
  api: {
    baseUrl: API_BASE_URL ?? "http://localhost:8000",
  },
  version: APP_VERSION,
  enviroment: ENVIRONMENT ?? "local",
  sentry: {
    dsn: SENTRY_DSN,
  },
};
