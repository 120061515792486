import { Alert, AlertProps, Stack } from "@mui/material";
import { createContext, useCallback, useContext, useState } from "react";

import pausableTimer from "./utils/pausableTimer";

export type NewToast = {
  type?: "success" | "error" | "warning" | "info";
  message: string;
  duration?: number;
  alertProps?: AlertProps;
};

type InternalToast = NewToast & {
  id: number;
  pauseDuration: () => void;
  resumeDuration: () => void;
};

type RemoveToastFunction = (id: number) => void;
type AddToastFunction = (toast: NewToast) => void;

type ToastContextType = AddToastFunction;

export const ToastContext = createContext<ToastContextType>(() => undefined);

export const DEFAULT_TOAST_DURATION = 5000;

let toastCount = 0;

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [toasts, setToasts] = useState<InternalToast[]>([]);

  const removeToast = useCallback<RemoveToastFunction>((id) => {
    setToasts((currentToasts) =>
      currentToasts.filter((toast) => toast.id !== id)
    );
  }, []);

  const addToast = useCallback<AddToastFunction>(
    ({ duration = DEFAULT_TOAST_DURATION, ...toast }) => {
      const id = toastCount++;
      const { pause, resume } = pausableTimer(() => {
        removeToast(id);
      }, duration);

      setToasts((currentToasts) => [
        ...currentToasts,
        {
          ...toast,
          duration,
          id,
          pauseDuration: pause,
          resumeDuration: resume,
        },
      ]);
    },
    [removeToast]
  );

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <Stack
        position="fixed"
        zIndex="snackbar"
        sx={{
          width: { xs: "100%", md: 384 },
          right: { xs: 0, md: 16 },
          bottom: { xs: 0, md: 16 },
          gap: 0.5,
        }}
      >
        {toasts.map((toast) => (
          <Toast key={toast.id} {...toast} removeToast={removeToast} />
        ))}
      </Stack>
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext<ToastContextType>(ToastContext);
};

const Toast = ({
  id,
  type,
  message,
  removeToast,
  alertProps,
  pauseDuration,
  resumeDuration,
}: InternalToast & {
  removeToast: RemoveToastFunction;
}): JSX.Element => {
  return (
    <Alert
      severity={type}
      onMouseEnter={pauseDuration}
      onMouseLeave={resumeDuration}
      onClose={() => removeToast(id)}
      {...alertProps}
    >
      {message}
    </Alert>
  );
};
