import fetcher, { mountUrl } from "./fetcher";
import stringfyParams, { ParametersObject } from "./utils/stringifyParams";

export function get<ResponseData, Params extends ParametersObject>(
  path: string,
  params: Partial<Params> = {},
  options: RequestInit = {}
): Promise<ResponseData> {
  const searchParams = stringfyParams(params ?? {});
  const url = `${mountUrl(path)}${searchParams ? `/?${searchParams}` : ""}`;
  return fetcher<ResponseData>(url, {
    method: "GET",
    ...options,
  });
}

export function post<Payload, ResponseData>(
  path: string,
  payload: Payload,
  options: RequestInit = {}
): Promise<ResponseData> {
  const body = JSON.stringify(payload);

  return fetcher<ResponseData>(mountUrl(path), {
    method: "POST",
    body,
    ...options,
  });
}

export function put<Payload, ResponseData>(
  url: string,
  payload: Payload,
  options: RequestInit = {}
): Promise<ResponseData> {
  const body = JSON.stringify(payload);

  return fetcher<ResponseData>(mountUrl(url), {
    method: "PUT",
    body,
    ...options,
  });
}

export function patch<Payload, ResponseData>(
  url: string,
  payload: Payload,
  options: RequestInit = {}
): Promise<ResponseData> {
  const body = JSON.stringify(payload);

  return fetcher<ResponseData>(mountUrl(url), {
    method: "PATCH",
    body,
    ...options,
  });
}

export function del<T>(url: string, options: RequestInit = {}): Promise<T> {
  return fetcher<T>(mountUrl(url), {
    method: "DELETE",
    ...options,
  });
}
