import { Route, Routes } from "react-router-dom";

import { useAuth } from "../auth/AuthContext";
import QueryErrorBoundary from "../common/components/error/QueryErrorBoundary";
import Page404 from "../common/components/layout/pages/Page404";

import BannersPage from "./pages/banners/BannersPage";
import CustomersPage from "./pages/customers/CustomersPage";
import ModulesPage from "./pages/modules/ModulesPage";
import ProductsPage from "./pages/products/ProductsPage";
import QuizzesPage from "./pages/quizzes/QuizzesPage";
import SolutionsPage from "./pages/solutions/SolutionsPage";
import UsersPage from "./pages/users/UsersPage";

const AdminRoutes = () => {
  const { adminRoles } = useAuth();

  return (
    <Routes>
      {adminRoles?.roles.includes("admin::solutions") && (
        <Route
          path="/solutions"
          element={
            <QueryErrorBoundary>
              <SolutionsPage />
            </QueryErrorBoundary>
          }
        />
      )}
      {adminRoles?.roles.includes("admin::products") && (
        <Route
          path="/products"
          element={
            <QueryErrorBoundary>
              <ProductsPage />
            </QueryErrorBoundary>
          }
        />
      )}
      {adminRoles?.roles.includes("admin::banners") && (
        <Route
          path="/banners"
          element={
            <QueryErrorBoundary>
              <BannersPage />
            </QueryErrorBoundary>
          }
        />
      )}
      {adminRoles?.roles.includes("admin::quizzes") && (
        <Route
          path="/quizzes"
          element={
            <QueryErrorBoundary>
              <QuizzesPage />
            </QueryErrorBoundary>
          }
        />
      )}
      {adminRoles?.roles.includes("admin::customers") && (
        <Route
          path="/customers"
          element={
            <QueryErrorBoundary>
              <CustomersPage />
            </QueryErrorBoundary>
          }
        />
      )}
      {adminRoles?.roles.includes("admin::modules") && (
        <Route
          path="/modules"
          element={
            <QueryErrorBoundary>
              <ModulesPage />
            </QueryErrorBoundary>
          }
        />
      )}
      {adminRoles?.roles.includes("admin::users") && (
        <Route
          path="/users"
          element={
            <QueryErrorBoundary>
              <UsersPage />
            </QueryErrorBoundary>
          }
        />
      )}
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default AdminRoutes;
