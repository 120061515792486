import { Button, ButtonProps } from "@mui/material";
import { forwardRef } from "react";
import { Link, LinkProps } from "react-router-dom";

export type ButtonLinkProps = ButtonProps<"a"> & LinkProps;

const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  function ButtonLinkComponent(props, ref) {
    return (
      <Button
        variant="text"
        component={Link}
        sx={{ gap: 1 }}
        ref={ref}
        {...props}
      >
        {props.children}
      </Button>
    );
  }
);

export default ButtonLink;
