import DarkModeIcon from "@mui/icons-material/DarkMode";
import HeadsetMicRoundedIcon from "@mui/icons-material/HeadsetMicRounded";
import LightModeIcon from "@mui/icons-material/LightMode";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Button,
  Divider,
  FormControl,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Toolbar,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { memo, useContext, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { MenuRootContext } from "../../menu";

import PinionLogo from "@/assets/pinion-logo.png";
import { useAuth } from "@/modules/auth/AuthContext";
import { useThemeModeStore } from "@/theme";

const ToggleThemeMenuItem = memo(function ToggleThemeMenuItemComponent() {
  const { mode, toggleMode } = useThemeModeStore();
  return (
    <MenuItem sx={{ textTransform: "capitalize" }} onClick={toggleMode}>
      <ListItemIcon>
        <IconButton sx={{ p: 0, mr: 3 }} color="inherit">
          {mode === "light" ? <DarkModeIcon /> : <LightModeIcon />}
        </IconButton>
      </ListItemIcon>
      {mode === "light" ? "Dark Mode" : "Light Mode"}
    </MenuItem>
  );
});

export const Topbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { open: menuOpen, toggleOpen } = useContext(MenuRootContext);
  const { logout, user, currentCustomer, changeCustomer, isFetchingMe } =
    useAuth();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const firstLetter = user?.name?.charAt(0).toUpperCase();

  const handleContact = () => {
    const mailto = "mailto:comercial@pinion.app";
    window.open(mailto, "_blank");
    handleCloseUserMenu();
  };

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{
        borderRadius: 0,
        backgroundColor: "transparent",
      }}
    >
      <Toolbar>
        {!menuOpen && (
          <Tooltip title={t("Expand Menu")}>
            <IconButton
              sx={{
                borderRadius: 1,
              }}
              onClick={toggleOpen}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
        )}

        <Stack
          direction="row"
          justifyContent="end"
          alignItems="center"
          flexGrow={1}
        >
          {(user?.customers ?? []).length > 1 && (
            <FormControl variant="standard" sx={{ m: 1, mr: 3, minWidth: 180 }}>
              <Select
                value={currentCustomer ?? ""}
                onChange={(e: SelectChangeEvent) => {
                  changeCustomer(e.target.value);
                  navigate("/");
                }}
                disabled={isFetchingMe}
              >
                {(user?.customers ?? []).map((customer) => (
                  <MenuItem key={customer.id} value={customer.id}>
                    {customer.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <IconButton
            onClick={handleOpenUserMenu}
            color="default"
            sx={{ p: 1 }}
          >
            <Avatar
              src={user?.image}
              alt={user?.name}
              sx={{
                height: 36,
                width: 36,
              }}
            >
              {firstLetter}
            </Avatar>
          </IconButton>

          <Menu
            anchorEl={anchorElUser}
            open={Boolean(anchorElUser)}
            variant="menu"
            onClose={handleCloseUserMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            keepMounted
          >
            <MenuItem
              component={Link}
              to="/profile"
              onClick={handleCloseUserMenu}
              sx={{
                minWidth: 200,
              }}
            >
              <ListItemIcon>
                <ManageAccountsIcon
                  sx={{
                    mr: 3,
                  }}
                />
              </ListItemIcon>
              <Trans>My account</Trans>
            </MenuItem>
            <ToggleThemeMenuItem />
            <MenuItem
              onClick={handleContact}
              sx={{
                minWidth: 200,
              }}
            >
              <ListItemIcon>
                <HeadsetMicRoundedIcon
                  sx={{
                    mr: 3,
                  }}
                />
              </ListItemIcon>
              <Trans>Contact us</Trans>
            </MenuItem>
            <MenuItem
              onClick={logout}
              sx={{
                minWidth: 200,
              }}
            >
              <ListItemIcon>
                <LogoutIcon
                  sx={{
                    mr: 3,
                  }}
                />
              </ListItemIcon>
              <Trans>Logout</Trans>
            </MenuItem>
          </Menu>
        </Stack>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

export const TopbarSkeleton = () => {
  return (
    <AppBar position="static" color="default" elevation={0}>
      <Toolbar>
        <Button size="large" color="primary" sx={{ mr: 2 }}>
          <img src={PinionLogo} alt="Pinion" height="28px" />
        </Button>
        <Box
          sx={{
            ml: "auto",
          }}
        >
          <IconButton sx={{ p: 1 }}>
            <Skeleton variant="circular" width={36} height={36} />
          </IconButton>
        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};
