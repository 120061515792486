import * as locales from "@mui/material/locale";
import { createTheme } from "@mui/material/styles";
import shadows from "@mui/material/styles/shadows";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export type SupportedLocales = keyof typeof locales;
export type ThemeMode = "light" | "dark";

const LOCALES = locales;

const getContainer = () => {
  return document.fullscreenElement ?? document.body;
};

shadows[6] = "0px 4px 8px rgba(0, 0, 0, 0.15)";

const getTheme = (
  mode: ThemeMode = "dark",
  locale: SupportedLocales = "enUS"
) =>
  createTheme(
    {
      palette: {
        mode,
        primary: {
          main: mode === "dark" ? "#266ED5" : "#0E5ED1",
        },
        secondary: {
          main: mode === "dark" ? "#873dbd" : "#6a0dad",
        },
        background: {
          default: mode === "dark" ? "#1F1F24" : "#F8FAFE",
          paper: mode === "dark" ? "#0e101a" : "#FAFAFA",
        },
      },
      shadows: shadows,
      components: {
        MuiPopper: {
          defaultProps: {
            container: getContainer,
          },
        },
        MuiMenu: {
          defaultProps: {
            container: getContainer,
          },
        },
      },
    },
    LOCALES[locale]
  );

const themeModeStore = create<{
  mode: ThemeMode;
  toggleMode: () => void;
}>()(
  persist(
    (set) => ({
      mode: window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light",
      toggleMode: () =>
        set((state) => ({
          mode: state.mode === "dark" ? "light" : "dark",
        })),
    }),
    {
      name: "theme-mode",
    }
  )
);

export const useThemeModeStore = themeModeStore;

export default getTheme;
