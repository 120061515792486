import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import Quizz from "../components/Quizz/Quizz";

import { useAuth } from "@/modules/auth/AuthContext";
import Carousel from "@/modules/common/components/Carousel";
import TitledPage from "@/modules/common/components/layout/pages/TitledPage";
import SolutionCard from "@/modules/common/components/SolutionCard";
import pdf_criancas from "@/modules/home/assets/dia-das-criancas.pdf";
import criancas from "@/modules/home/assets/dia-das-criancas.png";
import inapp from "@/modules/home/assets/in-app.jpg";
import iva from "@/modules/home/assets/iva.jpg";
import pdf_neura from "@/modules/home/assets/neura.pdf";
import neura from "@/modules/home/assets/neura.png";
import pdf_telas from "@/modules/home/assets/telas.pdf";
import telas from "@/modules/home/assets/telas.png";
import tracking from "@/modules/home/assets/tracking.jpg";

const carouselItems = [
  {
    title: "Comportamento de Compra no Dia das Crianças",
    description:
      "Metade do público que não tem filhos também compra presente para o dia das crianças . Confira esse e mais dados sobre o consumo da data.",
    imgPath: criancas,
    onClick: () => {
      window.open(pdf_criancas);
    },
  },
  {
    title: "Quem são os nossos clientes?",
    description:
      "Perguntamos para 880 brasileiros com filhos de até 12 anos algumas questões relacionadas ao uso do celular. Confira",
    imgPath: telas,
    onClick: () => {
      window.open(pdf_telas);
    },
  },
  {
    title: "A impermanência da Longevidade",
    description:
      "Em parceria com a agência Neura, desenvolvemos um estudo sobre como vemos o envelhecimento. Os dados deram alguns insights sobre como as marcas podem olhar para esse aspecto. Confira ",
    imgPath: neura,
    onClick: () => {
      window.open(pdf_neura);
    },
  },
];

const Home = () => {
  const { user } = useAuth();
  const userFirstName = user?.name.split(" ")[0] ?? "";

  return (
    <TitledPage title={`${t("Hi")}, ${userFirstName}`}>
      <Grid container spacing={3} sx={{ mb: "40px" }}>
        <Grid item xs={12} lg={8} sx={{ display: "flex" }}>
          <Box sx={{ flexGrow: 1, minHeight: "320px", maxWidth: "100%" }}>
            <Carousel items={carouselItems} />
          </Box>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Quizz />
        </Grid>
      </Grid>
      <Stack sx={{ pb: 5 }}>
        <Stack direction="row" sx={{ mb: "12px" }}>
          <Typography
            sx={{
              flexGrow: 1,
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            <Trans>Discover our solutions</Trans>
          </Typography>
          <Link to="/products">
            <Button variant="text" size="small">
              <Trans>See all</Trans>
            </Button>
          </Link>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} xl={3}>
            <SolutionCard
              badge="Midia"
              title="IVA"
              description="Um sistema de predição escalável e ágil capaz de identificar rapidamente o que tem maior potencial para atrair a atenção do olho humano."
              imgPath={iva}
              onClickLearnMore={() => {
                window.open("https://pinion.app/solucao/iva/");
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <SolutionCard
              badge="Inteligencia competitiva"
              title="INApp"
              description="Nossa plataforma coleta conteúdo de aplicativos, aplica OCR e extrai informações como preços, promoções, horários e métodos de pagamento em minutos."
              imgPath={inapp}
              onClickLearnMore={() => {
                window.open("https://pinion.app/solucao/in-app/");
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <SolutionCard
              badge="Inteligencia competitiva"
              title="Tracking de comportamento"
              description="Avalie o reconhecimento e demanda do produto/serviço, defina uma estratégia de marca apropriada e avalie o impacto das ações em mídia."
              imgPath={tracking}
              onClickLearnMore={() => {
                window.open("https://pinion.app/solucao/tracking-de-marcas/");
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </TitledPage>
  );
};

export default Home;
